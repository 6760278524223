<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview
          v-bind:title="'Danh sách chương trình khuyến mãi thu cũ Online'"
        >
          <template v-slot:toolbar>
            <div class="row">
              <div class="ml-3">
                <router-link
                  to="/tradein-discount-online/upsert-tradein-discount-online"
                  v-if="checkPermission('DISCOUNT_INSERT')"
                >
                  <button
                    v-bind:style="btnCreate"
                    type="button"
                    class="btn btn-primary font-weight-bolder btn-sm"
                  >
                    <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm
                    mới
                  </button>
                </router-link>
              </div>
            </div>
          </template>
          <template v-slot:preview>
            <!-- Header session -->
            <div class="col-md-12 mb-5">
              <b-form>
                <b-row>
                  <b-col style="padding-left: 0">
                    <b-input
                      placeholder="Tìm kiếm"
                      v-model="search"
                      append-icon="search"
                      single-line
                      hide-details
                      class="btn-sm"
                      v-on:input="fetchData()"
                    ></b-input>
                  </b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                </b-row>
              </b-form>
            </div>
            <!-- End of Header session -->
            <!-- Table session -->
            <b-table
              :items="accessories"
              :fields="fields"
              sort-by="count"
              class="table-bordered table-hover col-md-12"
              :busy="onLoading"
            >
              <template v-slot:table-busy>
                <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
              </template>
              <template v-slot:cell(discountAmount)="row">
                <span>{{ convertPrice(row.item.discountAmount) }}</span>
              </template>
              <template v-slot:cell(discountType)="row">
                <span>{{ amountTypes[row.item.discountType] }}</span>
              </template>

              <template v-slot:cell(status)="row">
                <span
                  v-if="row.item.status"
                  v-text="row.item.statusText"
                  class="label font-weight-bold label-lg label-light-success label-inline"
                ></span>
                <span
                  v-if="!row.item.status"
                  v-text="row.item.statusText"
                  class="label font-weight-bold label-lg label-light-danger label-inline"
                ></span>
              </template>
              <template v-slot:cell(startDate)="row">
                <span>Từ: {{ formatDate(row.item.startDate) }}</span>
                <span style="display: block">
                  Đến: {{ formatDate(row.item.endDate) }}
                </span>
              </template>
              <template v-slot:cell(actions)="row">
                <div class="d-flex justify-content-center">
                  <b-dropdown size="sm" id="dropdown-left" no-caret right>
                    <template slot="button-content">
                      <i
                        style="font-size: 1rem; padding-right: 0px"
                        class="flaticon2-settings"
                      ></i>
                    </template>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon-eye"></i>
                        &nbsp; Chi tiết
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="editItem(row.item)"
                      v-if="!checkViewOnly()"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i style="font-size: 1rem" class="flaticon2-pen"></i>
                        &nbsp; Chỉnh sửa
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteAlert(row.item)"
                      v-if="checkPermission('DISCOUNT_DELETE')"
                    >
                      <span style="color: #3f4254; font-size: 12px">
                        <i
                          style="font-size: 1rem; color: #d33"
                          class="flaticon2-rubbish-bin-delete-button"
                        ></i>
                        &nbsp; Xóa
                      </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <!-- End of Table session -->

            <!-- Paginate session -->
            <b-row>
              <b-col>
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số gói bảo hành:
                  {{ totalItems }}
                </p>
              </b-col>
              <b-col>
                <b-pagination-nav
                  class="custom-pagination"
                  v-show="totalPages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="totalPages"
                  use-router
                  @change="fetchData()"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
            <!-- End of Paginate session -->
          </template>
        </KTCodePreview>
      </div>
    </div>
    <b-modal ref="excel-modal" hide-footer title="Xuất excel" id="excel-modal">
      <v-form ref="form" lazy-validation>
        <div class="mb-4">
          <div class="row">
            <div class="col-6">
              <b-form-group label="Chọn cột cần xuất:">
                <b-form-radio-group
                  id="radio-group-excel-1"
                  v-model="selectedRowExcel"
                  name="radio-sub-component-1"
                  @change.native="showHeader"
                >
                  <b-form-radio value="all">Tất cả</b-form-radio>
                  <b-form-radio value="row">Chọn cột</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group label="Danh sách cần xuất:">
                <b-form-radio-group
                  id="radio-group-excel-2"
                  v-model="selectedListExcel"
                  name="radio-sub-component-2"
                >
                  <b-form-radio value="current">Trang hiện tại</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div>
            <div class="col-6" v-show="showHeaderExcel">
              <b-form-group>
                <template #label>
                  Cột:<br />
                  <b-form-checkbox
                    v-model="allSelected"
                    :indeterminate="indeterminate"
                    aria-describedby="items"
                    aria-controls="items"
                    @change="toggleAll"
                  >
                    {{ allSelected ? 'Bỏ chọn tất cả' : 'Tất cả' }}
                  </b-form-checkbox>
                </template>
                <b-form-checkbox-group
                  id="items"
                  v-model="selectedHeader"
                  :options="optionsHeader"
                  name="items"
                  stacked
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
        </div>

        <b-button
          style="width: 110px"
          variant="primary"
          size="sm"
          @click="reportClick"
          class="mr-3"
        >
          <v-icon small class="text-white" v-b-tooltip>mdi-download</v-icon>
          <strong>Xuất Excel</strong>
        </b-button>

        <b-button
          style="width: 100px"
          variant="secondary"
          size="sm"
          @click="$bvModal.hide('excel-modal')"
        >
          <strong>Hủy</strong>
        </b-button>
      </v-form>
    </b-modal>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import localData from '@/utils/saveDataToLocal';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import decounce from 'debounce';
import { VclTable } from 'vue-content-loading';
import moment from 'moment';
import axios from 'axios';
import { BASE_URL } from '@/utils/constants';
import { convertPrice } from '@/utils/common';

export default {
  data() {
    return {
      accessories: [],
      installments: [],
      onLoading: false,
      btnCreate: {
        fontWeight: '600!important',
      },
      isSearching: false,
      jobName: '',
      currentPage: 0,
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      search: '',
      employees: [],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      fields: [
        {
          key: 'name',
          label: 'Tên chương trình',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: 'calc(25% - 120px)',
          },
        },
        {
          key: 'description',
          label: 'Mô tả',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
        },
        {
          key: 'discountAmount',
          label: 'Chiết khấu',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            textAlign: 'center',
            color: '#181c32',
            width: 'calc(25% - 120px)',
          },
          tdClass: 'text-right',
        },
        {
          key: 'discountType',
          label: 'Kiểu chiết khấu',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            textAlign: 'center',
            color: '#181c32',
            width: 'calc(25% - 120px)',
          },
          tdClass: 'text-center',
        },
        {
          key: 'startDate',
          label: 'Thời gian',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'createdBy',
          label: 'Người tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'actions',
          label: '',
          thStyle: { fontWeight: 600, color: '#181c32', width: '50px' },
        },
      ],
      selectedRowExcel: 'all',
      selectedListExcel: 'current',
      showHeaderExcel: false,
      indeterminate: false,
      allSelected: false,
      optionsHeader: [],
      selectedHeader: [],
      optionsHeaderDefault: [],
      amountTypes: {
        1: 'Tiền ($)',
        2: 'Phần trăm (%)',
      },
    };
  },
  components: {
    KTCodePreview,
    Loader,
    VclTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Khuyến mãi thu cũ Online', route: 'tradein-discount-online' },
      { title: 'Danh sách khuyến mãi thu cũ Online' },
    ]);

    if (this.$route.params.createAccessorySuccess) {
      this.makeToastSuccess(this.$route.params.message);
      this.$route.params.createAccessorySuccess = false;
    }
    if (this.$route.params.updateInstallmentSuccess) {
      this.makeToastSuccess(this.$route.params.message);
      this.$route.params.updateInstallmentSuccess = false;
    }
  },
  created() {
    this.fetchData();
  },
  watch: {
    selectedHeader(newVal) {
      // Handle changes in individual flavour checkboxes
      if (newVal.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newVal.length === this.optionsHeader.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
  },
  methods: {
    convertPrice,
    editItem: function (item) {
      this.$router.push({
        name: 'upsert-tradein-discount-online',
        query: { id: item.id },
      });
    },
    formatDate: function (dateVal) {
      if (dateVal) {
        const date = moment(dateVal);
        return date.format('DD/MM/yyyy HH:mm');
      }
      return '';
    },
    async fetchData() {
      this.page = this.$route.query.page || 1;
      this.search = this.search || '';

      const params = {
        page: this.page,
        pageSize: 10,
        search: this.search,
        type: 2,
      };

      try {
        ApiService.setHeader();
        const response = await ApiService.query(`/tradein-discount-onlines`, {
          params,
        });
        const { result, totalPages, currentPage, totalItems } =
          response.data.data;

        this.accessories = result.map((item) => ({
          ...item,
          createdAt: item.createdAt,
          startDate: item.startDate,
          endDate: item.endDate
        }));

        this.totalPages = totalPages;
        this.currentPage = currentPage;
        this.totalItems = totalItems;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    showDeleteSuccess: function () {
      Swal.fire({
        title: 'Thông báo',
        text: 'Xóa nhân viên thành công!',
        icon: 'success',
        confirmButtonClass: 'btn btn-secondary',
      });
    },
    deleteItem: async function (item) {
      ApiService.delete(`tradein-discount-onlines/${item.id}`).then((data) => {
        const { status, message } = data.data;
        if (status === 1) {
          this.makeToastSuccess(message);
          this.fetchData();
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa chương trình khuyến mãi thu cũ Online!',
        text: 'Bạn có chắc muốn xóa chương trình khuyến mãi thu cũ Online này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    checkStatus: function (item) {
      if (item.isActive) {
        return true;
      } else {
        return false;
      }
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('DISCOUNT_VIEW')) {
        count++;
      }
      if (localData.checkPermission('DISCOUNT_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    excelModal: async function () {
      this.optionsHeaderDefault = [
        { text: 'ID', value: 'id', disabled: true },
        { text: 'Mã', value: 'code', disabled: true },
        { text: 'Tên', value: 'name', disabled: true },
      ];
      this.selectedHeader = [
        'id',
        'code',
        'name',
        'description',
        'createdBy',
        'startDate',
        'endDate',
        'createdAt',
        'status',
      ];
      this.optionsHeader = [
        { text: 'ID', value: 'id', disabled: true },
        { text: 'Mã', value: 'code', disabled: true },
        { text: 'Tên', value: 'name', disabled: true },
        { text: 'Mô tả', value: 'description', disabled: false },
        { text: 'Tạo bởi', value: 'createdBy', disabled: false },
        { text: 'Ngày bắt đầu', value: 'startDate', disabled: false },
        { text: 'Ngày kết thúc', value: 'endDate', disabled: false },
        { text: 'Ngày tạo', value: 'createdAt', disabled: false },
        { text: 'Trạng thái', value: 'status', disabled: false },
      ];
      this.$refs['excel-modal'].show();
    },
    reportClick: async function () {
      let data = {
        items: this.accessories,
        headers: this.selectedHeader,
        params: null,
      };
      if (this.selectedRowExcel !== 'row') {
        data.headers = this.optionsHeader.map((x) => x.value);
      }
      if (this.selectedListExcel !== 'current') {
        if (
          this.selectedCategory !== null ||
          this.search !== '' ||
          this.selectedStatus !== '' ||
          this.selectedProductType !== ''
        ) {
          this.childProductId = '';
          this.parentProductId = '';
        }
        data.params = {
          name: this.search,
          limit: 1000000,
          page: 1,
          categoryId: this.selectedCategory,
          currentSort: this.currentSort,
          currentSortDir: this.currentSortDir,
          status: this.selectedStatus,
          productType: this.selectedProductType,
          parentProductId: this.parentProductId,
          childProductId: this.childProductId,
        };
        data.items = [];
      }
      let baseUrl = BASE_URL;
      let url = `${baseUrl}warrantyPackages/export-excel`;
      
      await axios({
        url: url,
        method: 'POST',
        responseType: 'blob',
        data: data,
      }).then((response) => {
        let headerLine = response.headers['content-disposition'];
        let startFileNameIndex = headerLine.indexOf('"') + 1;
        let endFileNameIndex = headerLine.lastIndexOf('"');
        let filename = headerLine.substring(
          startFileNameIndex,
          endFileNameIndex,
        );
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    debounceInput: decounce(function () {
      this.searchEmployee();
    }, 2000),
    showHeader() {
      if (this.selectedRowExcel === 'row') {
        this.showHeaderExcel = true;
      } else {
        this.showHeaderExcel = false;
      }
    },
    toggleAll(checked) {
      this.selectedHeader = checked
        ? this.optionsHeader.map((x) => x.value)
        : this.optionsHeaderDefault.map((x) => x.value);
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style scoped>
.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}
</style>
